<template>
    <div class="sidebar-left">
        <div class="sidebar">

            <!-- Sidebar Content -->
            <div
                    class="sidebar-content card"
                    :class="{'show': mqShallShowLeftSidebar}"
            >

                <!-- ScrollArea: Chat & Contacts -->
                <vue-perfect-scrollbar
                        :settings="perfectScrollbarSettings"
                        class="chat-user-list-wrapper list-group scroll-area"
                >

                    <!-- Chats -->
                    <ul class="chat-users-list chat-list media-list">
                        <chat-contact
                                v-for="contact in contacts"
                                :key="contact.id"
                                :user="contact"
                                tag="li"
                                :class="{'active': activeChatContactId === contact.id}"
                                @click="$emit('open-chat', contact.id)"
                        />
                    </ul>
                </vue-perfect-scrollbar>
            </div>

        </div>
    </div>
</template>

<script>
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    import ChatContact from './ChatContact.vue'

    export default {
        components: {
            // 3rd party
            VuePerfectScrollbar,

            // SFC
            ChatContact,
        },
        props: {
            chatsContacts: {
                type: Array,
                required: true,
            },
            contacts: {
                type: Array,
                required: true,
            },
            shallShowUserProfileSidebar: {
                type: Boolean,
                required: true,
            },
            profileUserData: {
                type: Object,
                required: true,
            },
            profileUserMinimalData: {
                type: Object,
                required: true,
            },
            activeChatContactId: {
                type: Number,
                default: null,
            },
            mqShallShowLeftSidebar: {
                type: Boolean,
                required: true,
            },
        },
        emits: [
            'open-chat'
        ],
        setup() {
            const perfectScrollbarSettings = {
                maxScrollbarLength: 150,
            }


            return {
                // UI
                perfectScrollbarSettings,
            }
        },
    }
</script>

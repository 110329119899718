export default function useChat() {
  const resolveAvatarBadgeVariant = status => {
    if (status === 'OPENED') return 'success'
    if (status === 'CLOSED') return 'danger'
    if (status === 'CUSTOMER_ANSWERED') return 'warning'
    return 'secondary'
  }

  return {
    resolveAvatarBadgeVariant,
  }
}

<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
        class="body-content-overlay"
        :class="{'show': state.loading}"
    />

    <!-- Main Area -->
    <section class="chat-app-window">

      <!-- Start Chat Logo -->
      <div
          v-if="!activeChat.contact"
          class="start-chat-area"
      >
        <div class="mb-1 start-chat-icon">
          <feather-icon
              icon="MessageSquareIcon"
              size="56"
          />
        </div>
      </div>

      <!-- Chat Content -->
      <div
          v-else
          class="active-chat"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">

            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">

              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                    icon="MenuIcon"
                    class="cursor-pointer"
                    size="21"
                />
              </div>

              <b-avatar
                  size="36"
                  :variant="sectionVariant(activeChat.contact.type)"
                  class="mr-1 cursor-pointer badge-minimal"
                  badge
                  :badge-variant="status(activeChat.contact.status)"
              />
              <h6 class="mb-0">
                {{ activeChat.contact.customer }}
              </h6>
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar
            ref="perfect"
            :settings="perfectScrollbarSettings"
            class="user-chats scroll-area"
        >
          <chat-log
              :chat-data="activeChat"
              :profile-user-avatar="require('@/assets/images/avatars/avatar.jpg')"
          />
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <b-form
            class="chat-app-form"
            @submit.prevent="sendMessage"
        >
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input
                v-model="chatInputMessage"
                placeholder="Enter your message"
            />
          </b-input-group>
          <b-button
              variant="primary"
              type="submit"
          >
            ارسال
          </b-button>
        </b-form>
      </div>
    </section>

    <!-- Active Chat Contact Details Sidebar -->
    <chat-active-chat-content-details-sidedbar
        :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
        :contact="activeChat.contact || {}"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
          :chats-contacts="chatsContacts"
          :contacts="contacts"
          :active-chat-contact-id="activeId"
          :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
          :profile-user-data="profileUserData"
          :profile-user-minimal-data="profileUserDataMinimal"
          :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
          @open-chat="openChat"
      />
    </portal>

  </div>
</template>

<script>

// import "@/@core/scss/base/pages/app-chat.scss";
// import "@/@core/scss/base/pages/app-chat-list.scss";

import store from '@/store'
import {
  ref,
  onUnmounted,
  // nextTick,
} from '@vue/composition-api'
import {
  BAvatar,
  BForm,
  BInputGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import { formatDate } from '@core/utils/filter'
import {$themeBreakpoints} from '@/../themeConfig'
import {useResponsiveAppLeftSidebarVisibility} from '@core/comp-functions/ui/app'
import ChatLeftSidebar from './ChatLeftSidebar.vue'
import chatStoreModule from './chatStoreModule'
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import ChatLog from './ChatLog.vue'
import useChat from './useChat'

export default {
  components: {

    // BSV
    BAvatar,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
  },
  setup() {
    const CHAT_APP_STORE_MODULE_NAME = 'app-chat'

    // Register module
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.unregisterModule(CHAT_APP_STORE_MODULE_NAME)
    })

    const {resolveAvatarBadgeVariant} = useChat()

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)

    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------
    const chatsContacts = ref([])
    const contacts = ref([])

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({})
    // ? Will contain id, name and avatar & status
    const profileUserDataMinimal = ref({})

    const shallShowUserProfileSidebar = ref(false)

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const {mqShallShowLeftSidebar} = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }

    return {
      // Filters
      // formatDate,

      // useChat
      resolveAvatarBadgeVariant,

      // Chat & Contacts
      chatsContacts,
      contacts,

      // Single Chat
      refChatLogPS,
      // openChatOfContact,
      // sendMessage,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      // showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
    }
  },
  data() {
    return {
      activeId: null,
      activeChat: {
        contact: '',
        chat: '',
      },
      chatInputMessage: ''
    }
  },
  methods: {
    async sendMessage() {
      let formData = new FormData
      formData.append('text', this.chatInputMessage)
      const res = await this.$axios.post('/tickets/message/' + this.activeId, formData)
      await this.activeChat.chat.push(res.data.baseDTO)
      this.chatInputMessage = ''
      this.scrollToEnd()
    },
    async getTickets() {
      this.state.loading = true
      const [res,] = await this.$http.get('/tickets')
      if (res) {
        this.contacts = res.data.content
      }
    },
    async scrollToEnd() {
      // eslint-disable-next-line no-constant-condition
      while (true) {
        await this.$sleep(100)
        if (this.$refs.perfect) {
          const a = this.$refs.perfect.ps.contentHeight - this.$refs.perfect.ps.containerHeight
          this.$refs.perfect.$el.scrollTop = a
          break
        }
      }
    },
    async openChat(e) {
      if (this.activeId !== e) {
        this.state.loading = true
        this.activeId = e
        this.$forceUpdate()
        const [res,] = await this.$http.get(`/tickets/message/${e}?size=100`)
        if (res) {
          this.activeChat.contact = res.data.ticketDTO
          this.activeChat.chat = [res.data.ticketDTO, ...res.data.pageDTO.content.reverse()]
        }
        await this.$sleep(1000)
        this.scrollToEnd()
      }
    }
  },
  computed: {
    status() {
      const a = {
        OPENED: 'success',
        ADMIN_ANSWERED: 'success',
        PENDING: 'warning',
        CLOSED: 'secondary',
        CUSTOMER_ANSWERED: 'secondary'
      }
      return status => a[status] || 'info'
    },
    section() {
      const a = {
        TECHNICAL: 'فنی',
        GENERAL: 'عمومی'
      }
      return e => a[e]
    },
    sectionVariant() {
      const a = {
        TECHNICAL: 'dark',
        GENERAL: 'info'
      }
      return e => a[e]
    }
  },
  mounted() {
    this.getTickets()
  }
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "../../../@core/scss/base/pages/app-chat.scss";
@import "../../../@core/scss/base/pages/app-chat-list.scss";
</style>

<template>
    <component
            :is="tag"
            v-on="$listeners"
    >
        <b-avatar
                size="42"
                badge
                class="badge-minimal"
                :badge-variant="status(user.status)"
                :variant="'light-' + sectionVariant(user.type)"
        />
        <div class="chat-info flex-grow-1">
            <h5 class="mb-0">
                {{ user.customer }}
            </h5>
            <p class="card-text text-truncate">
                {{ user.subject }}
            </p>
        </div>
        <div class="chat-meta text-nowrap">
            <small class="float-right mb-25 chat-time">{{ $G2J(user.createdAtDateTime, 'mini') }}</small>
            <b-badge
                    pill
                    :variant="sectionVariant(user.type)"
                    class="float-right"
            >
                {{ section(user.type) }}
            </b-badge>
        </div>
    </component>
</template>

<script>
    import {BAvatar, BBadge} from 'bootstrap-vue'

    export default {
        components: {
            BAvatar,
            BBadge,
        },
        props: {
            tag: {
                type: String,
                default: 'div',
            },
            user: {
                type: Object,
                required: true,
            },
            isChatContact: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            status() {
                const a = {
                    OPENED : 'success',
                    ADMIN_ANSWERED : 'success',
                    PENDING : 'warning',
                    CLOSED : 'secondary',
                    CUSTOMER_ANSWERED : 'secondary'
                }
                return status => a[status] || 'info'
            },
            section(){
                const a = {
                    TECHNICAL : 'فنی',
                    GENERAL : 'عمومی'
                }
                return e => a[e]
            },
            sectionVariant(){
                const a = {
                    TECHNICAL : 'dark',
                    GENERAL : 'info'
                }
                return e => a[e]
            }
        }
    }
</script>

<style>

</style>

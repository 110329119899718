<template>
  <div class="chats">
    <div
      v-for="(msgGrp, index) in chatData.chat"
      :key="index"
      class="chat"
      :class="{'chat-left': msgGrp.customer}"
    >
      <div class="chat-avatar">
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="primary"
          :src="msgGrp.customer ? '' : profileUserAvatar"
        />
      </div>
      <div class="chat-body">
        <div
          class="chat-content"
        >
          <p>
            {{ msgGrp.text }}
            <b-button
                    size="sm"
                    v-if="msgGrp.file"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon rounded-circle ml-2"
                    @click="download(msgGrp.file)"
            >
              <feather-icon icon="DownloadIcon" />
            </b-button>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar,BButton } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BButton
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
    profileUserAvatar: {
      type: String,
      required: true,
    },
  },
  methods:{
    async download(name) {
      await this.$axios(
              {
                url: '/files/download',
                method: 'GET',
                responseType: 'blob', // important
                params: {
                  fileName: name
                },
                onDownloadProgress: (progressEvent) => {
                  console.log('process =>>', progressEvent)
                  this.load = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                },
              }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
    },
  }
}
</script>

<style>

</style>
